/**
 * Delay para el reseteo de los terms
 */
export const DELAY_RESET_TERM = 1000;

/**
 * Terminos que cuentas con `override_multiplier`
 */
export const FIELDS_WITH_OVERRIDE_MULTIPLIER = [
  "lat_long_list",
  "app_bundle_list",
  "deal_id_list",
  "placement_list",
  "domain_list",
];

export type ModifierOptionKey =
  | "content"
  | "geo"
  | "exchange"
  | "time"
  | "segment" // user
  | "platform"
  | "environment"
  | "app_site"
  | "video"
  | "audio";

export class ModifierOptionConfig {
  url: string;
  field: string;
  override_multiplier?: string | 0 | 1;

  constructor(payload?: Partial<ModifierOptionConfig>) {
    this.url = payload?.url ?? "";
    this.field = payload?.field ?? "";
    this.override_multiplier = payload?.override_multiplier ?? undefined;
  }

  hasOverride() {
    return this.override_multiplier === "true" || this.override_multiplier === 1;
  }
}

export class ModifierOption {
  content?: Record<string, ModifierOptionConfig>;
  geo?: Record<string, ModifierOptionConfig>;
  exchange?: Record<string, ModifierOptionConfig>;
  time?: Record<string, ModifierOptionConfig>;
  segment?: Record<string, ModifierOptionConfig>;
  platform?: Record<string, ModifierOptionConfig>;
  app_site?: Record<string, ModifierOptionConfig>;
  environment?: Record<string, ModifierOptionConfig>;
  video?: Record<string, ModifierOptionConfig>;
  audio?: Record<string, ModifierOptionConfig>;

  constructor(payload?: Record<ModifierOptionKey, Record<string, ModifierOptionConfig>>) {
    if (payload) {
      for (const key of Object.keys(payload) as ModifierOptionKey[]) {
        this[key] = this.createOptionConfigs(payload[key]);
      }
    }
  }

  private createOptionConfigs(data?: Record<string, ModifierOptionConfig>) {
    if (!data) return undefined;
    return Object.fromEntries(Object.entries(data).map(([subKey, value]) => [subKey, new ModifierOptionConfig(value)]));
  }

  getItems(key: ModifierOptionKey) {
    return this[key];
  }

  getFilteredItems(key: ModifierOptionKey, isDynamic: boolean) {
    const items = this.getItems(key);
    if (!items) return undefined;

    return Object.fromEntries(
      Object.entries(items).filter(([, config]) => (isDynamic ? config.hasOverride() : !config.hasOverride())),
    );
  }

  isItemOverride(key: ModifierOptionKey, nestedKey: string) {
    const item = this.getItems(key);
    if (!item || !item[nestedKey]) return undefined;
    return item[nestedKey].hasOverride();
  }
}

// export function hasOverride(key: any) {
//   return FIELDS_WITH_OVERRIDE_MULTIPLIER.some(f => f === key);
// }
