import { isRequired } from "@/services/rule-services";
import { TermValue } from "../ItemEntity";

export type ModifierFormField =
  | "id"
  | "external_id"
  | "name"
  | "advertiser_id"
  | "alternative_id"
  | "module"
  | "module_id"
  | "modifier_type"
  | "modifier_type_id"
  | "delivery_modifiers_option"
  | "default_weight"
  | "fallback_weight"
  | "active"
  | "terms"
  | "delivery_terms"
  | "bid_model_id";

export type ModifierTermField = "delivery";

export type ModifierResourceField =
  | "advertiser_id"
  | "modifier_type_id"
  | "module_id"
  | "bid_model_id"
  | "delivery_modifiers_option";

export class ModifierForm {
  id?: number;
  external_id?: number;
  name: string;
  advertiser_id?: number;
  alternative_id?: number;
  module?: TermValue;
  module_id?: number;
  modifier_type?: TermValue;
  modifier_type_id?: number;
  delivery_modifiers_option?: TermValue;
  default_weight?: string;
  fallback_weight?: string;
  active: boolean;
  terms: any[];
  delivery_terms: any[];
  bid_model_id?: number;

  constructor(payload?: Partial<ModifierForm>) {
    this.id = payload?.id;
    this.external_id = payload?.external_id;
    this.name = payload?.name ?? "";
    this.advertiser_id = payload?.advertiser_id;
    this.alternative_id = payload?.alternative_id;
    this.module = payload?.module;
    this.module_id = payload?.module_id;
    this.modifier_type = payload?.modifier_type;
    this.modifier_type_id = payload?.modifier_type_id;
    this.delivery_modifiers_option = payload?.delivery_modifiers_option;
    this.default_weight = payload?.default_weight;
    this.fallback_weight = payload?.fallback_weight;
    this.active = payload?.active ?? true;
    this.terms = payload?.terms ?? [];
    this.delivery_terms = payload?.delivery_terms ?? [];
    this.bid_model_id = payload?.bid_model_id;
  }
}

export class ModifierFormRule {
  external_id: any[];
  name: any[];
  advertiser_id: any[];
  alternative_id: any[];
  modifier_type: any[];
  module: any[];
  bid_model_id: any[];
  active: any[];
  delivery_modifiers_option: any[];

  constructor(payload?: Partial<ModifierFormRule>) {
    this.external_id = payload?.external_id ?? [];
    this.name = payload?.name ?? [];
    this.advertiser_id = payload?.advertiser_id ?? [];
    this.alternative_id = payload?.alternative_id ?? [];
    this.modifier_type = payload?.modifier_type ?? [];
    this.module = payload?.module ?? [];
    this.bid_model_id = payload?.bid_model_id ?? [];
    this.active = payload?.active ?? [];
    this.delivery_modifiers_option = payload?.delivery_modifiers_option ?? [];
  }

  async addRules() {
    this.name = [isRequired];
    this.modifier_type = [isRequired];
    this.advertiser_id = [isRequired];
    //this.module = [isRequired];
  }

  async resetRules() {
    this.name = [];
    this.modifier_type = [];
    this.advertiser_id = [];
    this.module = [];
  }
}

export class ModifierFormResource {
  modifier_type: any[];
  module: any[];
  bid_model_id: any[];
  delivery_modifiers_option: any[];

  constructor(payload?: Partial<ModifierFormResource>) {
    this.modifier_type = payload?.modifier_type ?? [];
    this.module = payload?.module ?? [];
    this.bid_model_id = payload?.bid_model_id ?? [];
    this.delivery_modifiers_option = payload?.delivery_modifiers_option ?? [];
  }

  getResource(field: ModifierFormField): any[] {
    return this[field];
  }

  setResource(field: ModifierFormField, values: any[]): void {
    this[field] = values;
  }
}

export class ModifierTerm {
  delivery: any[];

  constructor(payload?: Partial<ModifierTerm>) {
    this.delivery = payload?.delivery ?? [];
  }

  getResource(field: ModifierTermField): any[] {
    return this[field];
  }

  setResource(field: ModifierTermField, values: any[]): void {
    this[field] = values;
  }
}

export class ModifierResource {
  advertiser_id: any[];
  modifier_type_id: any[];
  module_id: any[];
  delivery_modifiers_option: any[];
  bid_model_id: any[];

  constructor(payload?: Partial<ModifierResource>) {
    this.advertiser_id = payload?.advertiser_id ?? [];
    this.modifier_type_id = payload?.modifier_type_id ?? [];
    this.module_id = payload?.module_id ?? [];
    this.delivery_modifiers_option = payload?.delivery_modifiers_option ?? [];
    this.bid_model_id = payload?.bid_model_id ?? [];
  }

  getResource(field: ModifierResourceField): any[] {
    return this[field];
  }

  setResource(field: ModifierResourceField, values: any[]): void {
    this[field] = values;
  }
}

export class ModifierLoading {
  advertiser_id: boolean;
  modifier_type_id: boolean;
  module_id: boolean;
  delivery_modifiers_option: boolean;
  bid_model_id: boolean;

  constructor(payload?: Partial<ModifierLoading>) {
    this.advertiser_id = payload?.advertiser_id ?? false;
    this.modifier_type_id = payload?.modifier_type_id ?? false;
    this.module_id = payload?.module_id ?? false;
    this.delivery_modifiers_option = payload?.delivery_modifiers_option ?? false;
    this.bid_model_id = payload?.bid_model_id ?? false;
  }

  isLoading(field: ModifierResourceField): boolean {
    return this[field];
  }

  setLoading(field: ModifierResourceField, values: boolean): void {
    this[field] = values;
  }
}
