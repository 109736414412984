import { ModifierModule, ModifierOptionEndpointKey, ModifierType, StaticItemKeys } from "@/services/Modifiers/types";
import { AlertMessage } from "@/models/Modifiers/alerts";

export const MAX_MODIFIER_VALUE = 10; // Max Modifier value
export const MATCHING_TYPE_DEFAULT = "Equals";
export const MATCHING_TYPE_IN_RANGE = "In range";
export const MIN_CONTENT_SEARCH = 3;
export const DEBOUNCE_DELAY = 750;

export const MODULES_BY_MODIFIER_TYPE: Record<ModifierType, ReadonlyArray<ModifierModule>> = {
  "Bid Modifier": ["app_site", "content", "environment", "exchange", "geo", "platform", "time", "user", "video", "audio"],
  "Delivery Modifier": ["app_site", "content", "environment", "exchange", "geo", "platform", "time", "audio"],
} as const;

export const ENDPOINT_KEY_BY_MODIFIER_TYPE: Record<ModifierType, ModifierOptionEndpointKey> = {
  "Bid Modifier": "modifiers_options",
  "Delivery Modifier": "delivery_modifiers_options",
} as const;

export const MODIFIER_OPTION_ENDPOINT_KEYS: ModifierOptionEndpointKey[] = [
  "modifiers_options",
  "delivery_modifiers_options",
];

export const MODIFIER_STATIC_ITEMS: Record<StaticItemKeys, any[]> = {
  delivery_modifiers_type: [
    {
      id: 101,
      value: "Width Term",
    },
  ],
};

export const MODIFIER_ALERTS: AlertMessage[] = [
  new AlertMessage({ show: false, key: "exist", message: "modifier.messages.term-exist", type: "warning", dismissible: true }),
  new AlertMessage({ show: false, key: "required", message: "modifier.messages.term-required", type: "error", dismissible: true }),
  new AlertMessage({ show: false, key: "max", message: "modifier.messages.term-max", type: "warning", dismissible: true }),
];

/**
 * Obtiene la configuracion inicial de las alertas en modifier:terms
 * @returns 
 */
export function getConfigAlerts(): AlertMessage[] {
  MODIFIER_ALERTS.forEach(alert => alert.show = false);
  return MODIFIER_ALERTS;
}