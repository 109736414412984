import ItemEntity from '@/models/ItemEntity'
import { AxiosResponse } from 'axios'
import { sortBy, uniq } from 'lodash'
import store from '@/store'

export const DEFAULT_SELECT_ALL_VALUE = 0;

/**
 * Add item to response data
 * @returns
 */
export async function addItemToList(
  response: AxiosResponse<any>,
): Promise<AxiosResponse<any>> {
  const item_identity = new ItemEntity()
  let newData: Array<any> = response.data.response
  newData = sortBy(newData, ['text'])
  newData.unshift(item_identity)
  response.data.response = uniq(newData)
  return response
}

export async function matchetkeyItems() {
  return {
    category: {
      items: store.state['person']['categories'],
      model: 'categories',
      filter: 'categoria',
    },
    subcategory: {
      items: store.state['person']['subcategories'],
      model: 'subcategories',
      filter: 'subcategoria',
    },
    department: {
      items: store.state['person']['departments'],
      model: 'departments',
      filter: 'dpto',
    },
    city: {
      items: store.state['person']['cities'],
      model: 'cities',
      filter: 'ciudad',
    },
    trademark: {
      items: store.state['person']['trademarks'],
      model: 'trademarks',
      filter: 'marca',
    },
    name: {
      items: store.state['person']['names'],
      model: 'names',
      filter: 'nombre',
    },
  }
}
