import ROUTES from "@/api/routes";
import { AxiosGet, GetData } from "@/services/axios-service";
import { catchServiceErrors, modes, prepareParams } from "@/utils/services-global";
import { FetchConfigParams, ModifierOptionEndpointKey, StaticItemKeys } from "@/services/Modifiers/types";
import { MODIFIER_STATIC_ITEMS } from "@/services/Modifiers/const";
import { SegmentFilter } from "@/interfaces/segment";
import { ResultPaginate } from "@/interfaces/paginated";

const ROUTE = ROUTES.MODIFIERS;
const ROUTE_GEO = ROUTES.GEO;
const ROUTE_SEGMENT = ROUTES.SEGMENT;

class ModifierTermService {
  /**
   * Obtener datos sin parametros
   * GET
   * @param key
   * @returns
   */
  async fetchGetData(key: ModifierOptionEndpointKey) {
    try {
      const response = await AxiosGet(MODIFIER_ROUTE_MAP[key]);
      return Promise.resolve(response.data);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  /**
   * Obtener datos con parametro `term` para busquedas
   * GET
   * @param params
   * @returns
   */
  async fetchSearchByTerm(params: { key: string; term: string }) {
    try {
      const response = await AxiosGet(`${MODIFIER_SEARCH_ROUTE_MAP[params.key]}?term=` + params.term);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  /**
   * Obtener datos estaticos
   * @param key
   * @returns
   */
  async fetchStaticData(key: StaticItemKeys) {
    return Promise.resolve(MODIFIER_STATIC_ITEMS[key] ?? []);
  }

  /**
   * Obtener datos con una configuracion para: paginacion, listado, download y all.
   * Con filtros y ordenacion.
   * @param params
   * @returns
   */
  async fetchWithConfig(params: FetchConfigParams): Promise<ResultPaginate> {
    try {
      const filter = params.filters ? getFilters(params.filters) : "";

      const url = await prepareParams({
        route: MODIFIER_SEARCH_ROUTE_MAP[params.key],
        entity: params.entity,
        paginated: params.paginated,
        fields: [],
        mode: params.mode ?? modes.PAGINATED,
        filter,
        options: params.options,
      });

      const response = await AxiosGet(url);
      return GetData(response);
    } catch (error) {
      return catchServiceErrors(error);
    }
  }
}

export default new ModifierTermService();

export const MODIFIER_ROUTE_MAP: Record<ModifierOptionEndpointKey, string> = {
  modifiers_options: ROUTE.MODULE_MODIFIER,
  delivery_modifiers_options: ROUTE.MODULE_DELIVERY_MODIFIER,
};

export const MODIFIER_SEARCH_ROUTE_MAP: Record<string, string> = {
  country: ROUTE_GEO.COUNTRY_ROUTE,
  region: ROUTE_GEO.REGION_ROUTE,
  city: ROUTE_GEO.CITY_ROUTE,
  segment: ROUTE_SEGMENT.SEGMENTS_ROUTE,
};

export function getFilters(filters: SegmentFilter): string {
  const filterEntries = Object.entries({
    "filters[id]": filters.id,
    "filters[external_id]": filters.external_id,
    "filters[name]": filters.name,
    "filters[advertisers.name]": filters.advertiser_name,
    "filters[alternative_id]": filters.alternative_id,
    "filters[advertiser_id]": filters.advertiser_id,
    "filters[key]": filters.key,
    "filters[description]": filters.description,
    "filters[verb_usage_id]": filters.verb_usage_id,
    "filters[created_at]": filters.created_at,
    "filters[updated_at]": filters.updated_at,
    "filters[active]": filters.active !== undefined ? (filters.active ? 1 : 0) : undefined,
  });

  return filterEntries
    .filter(([, value]) => value !== undefined && value !== "")
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
}
