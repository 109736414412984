import { ItemAll } from "@/interfaces/person";
import i18n from "@/plugins/i18n";
import { DEFAULT_SELECT_ALL_VALUE } from "@/utils/personData";

export default class ItemEntity implements ItemAll {
  id: any = DEFAULT_SELECT_ALL_VALUE;
  text: any = i18n.t("persons.selectable.selectAll");
  pois: number = 0;

  constructor() {}
}

export class TermValue {
  id: string | number;
  value: string;

  constructor(id: string | number, value: string) {
    this.id = id;
    this.value = value;
  }
}

export class ItemBuilder {
  private items: TermValue[] = [];

  /**
   * Convierte un objeto en un array de { id, value } con opciones configurables.
   * @param obj Objeto con los datos a transformar.
   * @param useValueAsId Si `true`, usa el valor en lugar de la clave como `id`.
   * @param useValueAsValue Si `true`, usa el valor en lugar de la clave como `value`.
   */
  public fromObject(obj: Record<string, string>, useValueAsId: boolean = false, useValueAsValue: boolean = true): this {
    this.items = Object.entries(obj).map(([key, value]) => ({
      id: useValueAsId ? value : key,
      value: useValueAsValue ? value : key,
    }));
    return this;
  }

  /**
   * Retorna el array final de items.
   */
  public build(): TermValue[] {
    return this.items;
  }

  // 1. Uso estándar (id = clave, value = valor)
  // const items1 = new ItemBuilder().fromObject(data).build();

  // 2. Usar `value` como `id` y `value`
  // const items2 = new ItemBuilder().fromObject(data, true, true).build();

  // 3. Usar `value` como `id` pero clave como `value`
  // const items3 = new ItemBuilder().fromObject(data, true, false).build();

  // 4. Usar clave como `id` y clave como `value`
  // const items4 = new ItemBuilder().fromObject(data, false, false).build();
}
