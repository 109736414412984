import { PlanningV10 } from "@/interfaces/persons/v10/route";

export const LOGIN_ROUTE = "/api/auth/login";
export const LOGOUT_ROUTE = "/api/auth/logout";
export const PROFILE_ROUTE = "/api/auth/me";
export const FORGOT_ROUTE = "/api/auth/password/forgot";
export const RESET_ROUTE = "/api/auth/password/reset";
export const REFRESH_ROUTE = "api/auth/refresh";
export const PERMISSION_ROUTE = "/api/auth/permissions";
export const INITIALIZE_ROUTE = "/api/auth/init";
export const ACCOUNT_ROUTE = "/api/account";
export const ACCOUNT_LIST_ROUTE = "/api/account/list";
export const ACCOUNT_COLORS_ROUTE = "/api/account/set_colors";
export const ACCOUNT_LIST_COLORS_ROUTE = "/api/account/get_colors";
export const ACCOUNT_DEFAULT_COLORS_ROUTE = "/api/account/get_default_colors";
export const USER_ROUTE = "/api/users";
export const EMAIL_RESEND_ROUTE = "/api/users/resend_email";
export const CODE_QR_GOOGLE_ROUTE = "/api/users/get_two_factor_code";
export const SET_TO_FACTOR_ROUTE = "/api/users/set_two_factor_authentication_method";
export const TWOFA_ROUTE = "api/auth/two_factor_verification ";
export const ADVERTISER_ROUTE = "/api/advertisers";
export const ADVERTISER_CATEGORIES_ROUTE = "/api/list/advertiser_categories";
export const ADVERTISER_MAKERS_ROUTE = "/api/list/advertiser_makers";
export const ADVERTISER_TRADE_MARKS_ROUTE = "/api/list/advertiser_trade_marks";
export const TIMEZONE_ROUTE = "/api/list/timezones";
export const TIMEZONE_ASSOCIATION_ROUTE = "api/line_items/timezone_association";
export const CURRENCY_ROUTE = "/api/list/currencies";
export const CUSTOM_LIST_ROUTE = "/api/custom_lists";
export const CUSTOM_LIST_EXCHANGE_ROUTE = "/api/list/custom_list_exchanges";
export const CUSTOM_LIST_TYPES_ROUTE = "/api/list/custom_list_types";
export const CUSTOM_LIST_CLEAR_ROUTE = "/api/custom_list_items/clear";
export const LIST_ITEM_ROUTE = "/api/custom_list_items";
export const MODIFIER_TYPE_ROUTE = "api/list/modifier_types";
export const MODIFIER_MODULE_ROUTE = "api/list/modifier_modules";
export const MODIFIER_ROUTE = "/api/modifiers";
export const DELIVERY_MODIFIER_ROUTE = "/api/delivery_modifiers";
export const MATCHING_ROUTE = "api/list/matching_types";
export const UNIT_TIME_ROUTE = "api/list/unit_times";
export const BUDGET_ROUTE = "api/list/budget_types";
export const CAMPAING_KPI_ROUTE = "api/list/kpi_campaigns";
export const STRATEGY_ROUTE = "api/list/strategies";
export const STRATEGY_OPTIMIZATION_ROUTE = "api/list/optimization_strategies";
export const CAMPAING_PACING_ROUTE = "api/list/campaign_pacing";
export const CAMPAIGN_ROUTE = "api/campaigns";
export const CAMPAIGN_TERMS_ROUTE = "api/campaigns/get_terms_conditions_accepted";
export const MODIFIER_ASSOCIATION_ROUTE = "api/campaigns/modifier_association";
export const COUNTRY_ROUTE = "api/list/countries";
export const REGION_ROUTE = "api/list/regions";
export const CITY_ROUTE = "api/list/cities";
export const LAT_LONG_ROUTE = "api/list/custom_lists/lat_long";
export const LOCATION_TYPE_ROUTE = "api/list/targeting_location_types";
export const DOMAIN_LIST_ROUTE = "api/list/custom_lists/domain";
export const BID_SHADING_ROUTE = "api/list/bid_shadings";
export const BID_STRATEGY_ROUTE = "api/list/bid_strategy";
export const BID_RATE_ROUTE = "api/list/video_bid_rates";
export const LINE_PACING_ROUTE = "api/list/line_pacing";
export const PACING_BEHAVIOUR_ROUTE = "api/list/pacing_behaviors";
export const LINE_ITEM_TYPE_ROUTE = "api/list/line_items_types";
export const LINE_ITEMS_ROUTE = "api/line_items";
export const CREATIVE_METHOD_ROUTE = "api/list/creatives_methods";
export const CREATIVE_TYPE_ROUTE = "api/list/creative_types";
export const CREATIVE_TEMPLATE_ROUTE = "api/list/creative_templates";
export const CREATIVE_VENDOR_ROUTE = "api/list/creative_vendors";
export const CREATIVE_RULE_ROUTE = "api/list/creative_rules";
export const CREATIVE_MIME_ROUTE = "api/list/mime_types";
export const CREATIVE_AUDIO_MIME_ROUTE = "api/list/audio_mime_types";
export const CREATIVE_EXPANDABLE_TYPE_ROUTE = "api/list/expandable_types";
export const CREATIVE_EXPANDABLE_DIRECTION_ROUTE = "api/list/expandable_directions";
export const CREATIVE_VIDEO_ROUTE = "api/list/inbanner_videos";
export const CREATIVE_APIS_ROUTE = "api/list/video_apis";
export const CREATIVE_TAG_ROUTE = "api/creatives/check_tag";
export const CREATIVE_ASSOCIATE_ROUTE = "api/creative_line_associations";
export const CREATIVE_ADDONS_ROUTE = "api/creative_addons";
export const CREATIVE_ASSETS_ROUTE = "api/creative_assets";
export const CREATIVE_ROUTE = "api/creatives";
export const CREATIVE_ASSOCIATION_ROUTE = "/api/creative-line-association";
export const APP_BUNDLE_LIST_ROUTE = "api/list/custom_lists/app_bundle";
export const APP_ID_ROUTE = "api/list/custom_lists/app_id";
export const APP_NAME_ROUTE = "api/list/app_name";
export const DEAL_ID_ROUTE = "undefined_url";
export const DEAL_ID_LIST_ROUTE = "api/list/custom_lists/deal_id";
export const DOMAIN_ROUTE = "api/list/custom_lists/domain";
export const PLACEMENT_ROUTE = "api/list/custom_lists/placement_id";
export const PUBLISER_ID_ROUTE = "undefined_url";
export const PUBLISER_ID_LIST_ROUTE = "api/list/custom_lists/publisher_id";
export const SITE_ROUTE = "api/list/site_id";
export const SITE_LIST_ROUTE = "api/list/custom_lists/site_id";
export const AUCTION_TYPE_ROUTE = "api/list/auction_types";
export const INVENTORY_SOURCE_ROUTE = "api/list/inventory_sources";
export const INVENTORY_VIDEO_APIS = "api/list/video_apis";
export const TARGETING_ROUTE = "api/targeting_expressions";
export const TARGETING_PREDICATE_ROUTE = "api/list/targeting_predicates";
export const TARGETING_KEY_ROUTE = "api/targeting_keys";
export const TARGETING_LOCATION_TYPE_ROUTE = "api/list/targeting_location_types";
export const TARGETING_MODULE_ROUTE = "api/list/targeting_modules";
export const SUMMARY_TARGETING_EXPRESSIONS_ROUTE = "/api/summary_targeting_expressions";

// ENVIRONMENT
export const INTERSTITIAL_FLAGS = "/api/list/interstitial_flags";
export const INTESTITIAL_TYPES = "api/list/interstitial_types";
export const ENVIRONMENT_VIDEO_ROUTE = "api/list/video_apis";
export const ENVIRONMENT_TPFRAME_ROUTE = "api/list/topframes";
export const CREATIVE_SIZE_ROUTE = "api/list/creative_sizes";
export const AD_POSITION_ROUTE = "api/list/ad_positions";
export const ENVIRONMENT_TYPE_ROUTE = "api/list/environment_types";
export const ENVIRONMENT_REWARDED_ROUTE = "api/list/rewarded";
export const NATIVE_NATIVE_LAYOUT_ROUTE = "api/list/native_layouts";

export const PLATFORM_BANDWITDH = "api/list/bandwidths";
export const PLATFORM_BROWSER_ROUTE = "api/list/browsers";
export const PLATFORM_BROWSER_VERSION_ROUTE = "api/list/browser_versions";
export const PLATFORM_CARRIER_ROUTE = "api/list/carriers";
export const PLATFORM_DEVICE_TYPE_ROUTE = "api/list/devices";
export const PLATFORM_DEVICE_MAKE_ROUTE = "api/list/device_makes";
export const PLATFORM_DEVICE_MODEL_ROUTE = "api/list/device_models";
export const PLATFORM_DEVICE_SCREEN_SIZE_ROUTE = "api/list/device_screen_sizes";
export const PLATFORM_OPERATING_SYSTEM_ROUTE = "api/list/operating_systems";
export const PLATFORM_OPERATING_SYSTEM_VERSION_ROUTE = "api/list/operating_system_versions";
export const CONTENT_CATEGORY_ROUTE = "api/list/content_categories";
export const CONTENT_LENGUAGE_ROUTE = "api/list/languages";
export const CONTENT_RATINGS_ROUTE = "api/list/content_ratings";
export const VIDEO_COMPANION_REQUIRED = "api/list/companion_support";
export const VIDEO_PLAYBACK_METHOD = "api/list/video_playback_methods";
export const VIDEO_START_DELAY = "api/list/video_start_delays";
export const VIDEO_PLAYER_SIZE = "api/list/video_player_sizes";
export const VIDEO_PLACEMENT_TYPE = "api/list/video_placement_types";
export const TYPES_FRECUENCY_CAPS_ROUTE = "/api/list/frequency_cap_type";
export const SET_COLOR_ROUTE = "/api/account/design_settings";

export const SEGMENTS_ROUTE = "/api/segments";
export const SEGMENTS_USERS_ROUTE = "/api/list/user_ids";
export const SEGMENTS_CATEGORY_ROUTE = "api/segment_categories";
export const SEGMENT_PROVIDERS_ROUTER = "api/list/segment_providers";
export const SEGMENTS_CATEGORY_LIST_ROUTE = "api/list/segment_categories";
export const SEGMENTS_THIRDPARTY_ROUTE = "api/third_party/segments";
export const SEGMENTS_CATEGORY_THIRDPARTY_ROUTE = "api/third_party/categories";
export const SEGMENT_TAG_ROUTE = "api/third_party/segment_tag";
export const SEGMENT_TREE_VIEW_ROUTE = "/api/segments/view/tree";
export const SEGMENT_GENERATED_TAG_ROUTE = "api/segment_tag";
export const SEGMENT_THIRD_TREE = "api/third_party/category_segment_tree";
export const SEGMENT_SEARCH = "/api/segments/search";

export const MODELS_CREATE_ROUTE = "/api/bidmodels";
export const MODELS_PAGINATED_ROUTE = "/api/bidmodels";
export const MODELS_METRICS_LIST_ROUTE = "/api/bidmodels/get_metrics";
export const BIDMODELS_LIST_ROUTE = "/api/bidmodels";

export const LOOKALIKE_CREATE_ROUTE = "/api/lookalike/spawn_seed";
export const LOOKALIKE_ACTIVATE_ROUTE = "/api/lookalike/activate";
export const LOOKALIKE_PAGINATED_ROUTE = "/api/lookalikes";

//EVENTS
export const EVENT_ROUTE = "/api/events";
export const EVENT_ATTRIBUTIONS_ROUTE = "/api/list/event_attributions";
export const EVENT_TYPES_ROUTE = "/api/list/event_types";
export const EVENT_TAG_TYPES_ROUTE = "/api/list/event_tag_type";
export const EVENT_GENERATE_TAG_ROUTE = "/api/events/event_tag";

//EVENTS ASSOCIATE
export const EVENT_ASSOCIATIONS_LINE_ROUTE = "/api/events/line_item_association";
export const EVENT_ASSOCIATIONS_CAMPAIGN_ROUTE = "/api/events/campaign_association";

export const LINE_ASSOCIATIONS_EVENT_ROUTE = "/api/line_items/event_association";
export const CAMPAIGN_ASSOCIATIONS_EVENT_ROUTE = "/api/campaigns/event_association";

export const LINE_ITEMS_MODIFIER_ASSOCIATION_ROUTE = "api/line_items/modifier_association";

export const LINE_ITEM_BID_WIN_ROUTE = "/api/line_items/daily_bid_win";
export const CREATIVE_LINE_ASSOCIATIONS_ROUTE = "/api/creative_line_associations";

export const REPORT_ROUTE = "api/reports";
export const REPORT_TYPE_ROUTE = "api/list/report_types";
export const REPORT_DATA_RANGE_ROUTE = "api/list/report_data_range";
export const REPORT_ADVERTISER_ROUTE = "api/list/report_advertisers";
export const REPORT_CAMPAIGN_ROUTE = "api/list/report_campaigns";
export const REPORT_LINE_ROUTE = "api/list/report_line_items";
export const REPORT_CREATIVE_ROUTE = "api/list/report_creatives";
export const REPORT_FORMAT_TYPE_ROUTE = "api/list/report_format_types";
export const REPORT_FIELD_ROUTE = "api/list/report_fields";
export const REPORT_GET_DIMENSIONS_ROUTE = "api/reports/get_dimensions/";
export const REPORT_ALL_TYPE_ROUTE = "api/reports/get_types";
export const REPORT_GET_METRICS_ROUTE = "api/reports/get_metrics/";
export const REPORT_GET_SCHEDULER_TYPES_ROUTE = "api/reports_schedule/get_types";
export const REPORT_SCHEDULER_ROUTE = "api/reports_schedule";
export const REPORT_SCHEDULER_BULK_ROUTE = "api/reports_schedule/bulk";
export const REPORT_COUNTRY_ROUTE = "/api/list/on_demand_countries";
export const REPORT_DEMAND_STORE_ATTRIBUTION_ROUTE = "/api/on_demand_report/store_attribution";
export const REPORT_DEMAND_GEO_ROUTE = "/api/on_demand_report/geo_report";
export const REPORT_DEMAND_CAMPAIGN_INSIGHT_ROUTE = "/api/on_demand_report/campaign_insight";
export const REPORT_DEMAND_UNIQUE_DEVICES_ROUTE = "/api/on_demand_report/unique_devices";
export const REPORT_DEMAND_LIST_ROUTE = "/api/list/custom_lists/lat_long";
export const REPORT_ON_DEMAND_ROUTE = "/api/on_demand_report";
export const REPORT_MY_REPORT_ROUTE = "/api/reports/get_reports";
export const REPORT_COUNTRY_CAMPAIGN_ROUTE = "/api/list/countries";
export const REPORT_DOWNLOAD_ON_DEMAND_ROUTE = "/api/on_demand_report/download/";
export const REPORT_PERFORMANCE_CAMPAIGN_LOOKER_URL_ROUTE = "/api/reports/performance_campaign_looker_url";
export const REPORT_PARTIAL_LOOKER_URL_ROUTE = "/api/reports/partial_report_looker_url";
export const REPORT_FINAL_LOOKER_URL_ROUTE = "/api/reports/final_report_looker_url";

export const PERSON_COUNTRY_ROUTE = "c";
export const PERSON_DPTO_ROUTE = "api/persons/departments";
export const PERSON_CITY_ROUTE = "api/persons/cities";
export const PERSON_CATEGORY_ROUTE = "api/persons/categories";
export const PERSON_SUBCATEGORY_ROUTE = "api/persons/subcategories";
export const PERSON_BRAND_ROUTE = "api/persons/brands";
export const PERSON_NAME_ROUTE = "api/persons/names";
export const PERSON_POIS_GRAPHIC = "api/persons/pois_graphics";
export const PERSON_REFRESH_AUDIENE = "/api/persons/audience_graphics";
export const PERSON_POIS_MAP = "/api/persons/pois_map";
export const PERSON_AUDIENCE_MAP = "/api/persons/audience_map";
export const PERSON_EXPORT_AUDIENCE = "/api/persons/create_segment";
export const PERSON_PRIVATE_POIS_ROUTE = "/api/personas/private_pois";
export const PERSON_GET_PRIVATE_POIS_ROUTE = "/api/personas/private_pois";
export const PERSON_GET_COUNTRIES_PRIVATE_POIS_ROUTE = "/api/personas/countries";
export const PERSON_GET_DEPARTMENTS_PRIVATE_POIS_ROUTE = "/api/persons/private_pois/departments";
export const PERSON_GET_CITIES_PRIVATE_POIS_ROUTE = "/api/persons/private_pois/cities";
export const PERSON_GET_CATEGORIES_PRIVATE_POIS_ROUTE = "/api/persons/private_pois/categories";
export const PERSON_GET_SUBCATEGORIES_PRIVATE_POIS_ROUTE = "/api/persons/private_pois/subcategories";
export const PERSON_GET_BRANDS_PRIVATE_POIS_ROUTE = "/api/persons/private_pois/brands";
export const PERSON_GET_NAMES_PRIVATE_POIS_ROUTE = "/api/persons/private_pois/names";
export const PERSONA_CREATE_LAYER_PRIVATE_POIS_ROUTE = "/api/persons/private_pois/save_layer";
export const PERSON_EXPORT_TO_LIST_ROUTE = "/api/persons/export_lat_lon_list";

// POIS neighborhood
export const PERSON_NEIGHBORHOOD_ROUTE = "/api/persons/neighborhood";
export const PERSON_PRIVATE_NEIGHBORHOOD_ROUTE = "/api/persons/private_pois/neighborhood";

// AUDIENCE

export const AUDIENCE_DATES_ROUTE = "/api/persons/users_by_date";
export const AUDIENCE_DISTANCES_ROUTE = "/api/persons/users_by_distance";
export const AUDIENCE_WEEK_DAY_ROUTE = "/api/persons/users_by_week_day";
export const AUDIENCE_HOUR_DAY_ROUTE = "/api/persons/users_by_hour_day";

// AUDIENCE pois
export const AUDIENCE_CATEGORIES_ROUTE = "/api/persons/users_by_category";
export const AUDIENCE_SUBCATEGORIES_ROUTE = "/api/persons/users_by_subcategory";
export const AUDIENCE_DEPARTMENTS_ROUTE = "/api/persons/users_by_dpto";
export const AUDIENCE_CITIES_ROUTE = "/api/persons/users_by_poi_city";
export const AUDIENCE_NEIGHBORHOOD_ROUTE = "/api/persons/users_by_poi_neighborhood";
export const AUDIENCE_BRANDS_ROUTE = "/api/persons/users_by_marca";
export const AUDIENCE_NAMES_ROUTE = "/api/persons/users_by_poi_name";

// AUDIENCE demographics
export const AUDIENCE_GENDERS_ROUTE = "/api/persons/users_by_gender";
export const AUDIENCE_AGES_ROUTE = "/api/persons/users_by_age";
export const AUDIENCE_RESIDENCE_CITIES_ROUTE = "/api/persons/users_by_ciudad_residencia";
export const AUDIENCE_NEIGHBORHOOD_RESIDENCE_ROUTE = "/api/persons/users_by_barrio_residencia";
export const AUDIENCE_SOCIOECONOMICS_ROUTE = "/api/persons/users_by_niv_socio";
export const AUDIENCE_SOCIOECONOMICS_ROUTE_BR = "/api/persons/users_by_estrato_br";

// AUDIENCE online_behaviour
export const AUDIENCE_IDENTIFIER_TYPE_ROUTE = "/api/persons/users_by_type";
export const AUDIENCE_NAVIGATION_BEHAVIOUR_ROUTE = "/api/persons/users_by_iab";
export const AUDIENCE_CAMPAIGN_INTERESTS_ROUTE = "/api/persons/users_by_intereses";
export const AUDIENCE_APP_VISITED_ROUTE = "/api/persons/users_by_app_name";
export const AUDIENCE_SITES_VISITED_ROUTE = "/api/persons/users_by_domain";
export const AUDIENCE_CITY_SEEN_ROUTE = "/api/persons/users_by_city_seen";

// AUDIENCE device
export const AUDIENCE_BROWSER_ROUTE = "/api/persons/users_by_platform_browser";
export const AUDIENCE_LANGUAGE_ROUTE = "/api/persons/users_by_device_language";
export const AUDIENCE_MAKE_ROUTE = "/api/persons/users_by_device_make";
export const AUDIENCE_DEVICE_TYPE_ROUTE = "/api/persons/users_by_platform_device_type";
export const AUDIENCE_OPERATING_SYSTEM_ROUTE = "/api/persons/users_by_platform_os";
export const AUDIENCE_OPERATOR_ROUTE = "/api/persons/users_by_carrier";

// GENERATE AUDIENCE
export const PERSON_GENERATED_AUDIENCE = "api/persons/generate_audience";

// GENERATE DEMO
export const PERSON_GENERATE_DEMO = "api/persons/generate_demo";
// GENERATE DEMO
export const PERSON_FILTER_AUDIENCE_ROUTE = "api/persons/filter_audience";

// GENDER_AGE
export const PERSON_GENDER_AGE = "api/persons/users_by_pois_gender_age";
export const PERSON_GENDER_AGE_DOWNLOAD = "api/persons/users_by_pois_gender_age_download";

// HEAD_MAP
export const PERSON_HOUR_DAY_WEEK = "api/persons/users_by_hour_day_week";

export const AUDIENCE_GRAPHICS_CATEGORY_ROUTE = "/api/persons/category_graph";
export const AUDIENCE_GRAPHICS_DAY_HOUR_ROUTE = "/api/persons/day_hour_graph";
export const AUDIENCE_GRAPHICS_DATE_ROUTE = "/api/persons/date_graph";
export const AUDIENCE_GRAPHICS_TOTAL_CATEGORIES_ROUTE = "/api/persons/total_categories_graph";
export const AUDIENCE_GRAPHICS_TOTAL_SUMMARY_ROUTE = "/api/persons/total_summary_point_graph";

export const MODULE_MODIFIER = "/api/modifiers_options";
export const MODULE_DELIVERY_MODIFIER = "/api/delivery_modifiers_options";

export const CREATIVE_BULK_ROUTE = "/api/creative_bulk";

export const REPORT_LIST_ROUTE = "/api/list/report_list";
export const LOOKER_ENDPOINT = "/api/reports/generate_looker_url";
export const REPORT_DIMENSION_AND_METRIC_SP_ROUTE = "/api/reports/get_metrics_and_dimensions_sp";
export const REPORT_SP_ROUTE = "/api/reports/get_report_sp";
export const REPORT_FILTERS_SP = "/api/reports/get_filters_sp";
export const REPORT_DATA_RANGE_SP_ROUTE = "/api/reports/get_data_range_sp";
export const REPORT_FORMAT_TYPE_SP_ROUTE = "/api/reports/get_types_sp";
export const REPORT_ACCOUNT_SP_ROUTE = "/api/reports/get_accounts_sp";

export const CLONE_CAMPAIGN_ROUTE = "/api/campaigns/clone";
export const CLONE_LINE_ITEM_ROUTE = "/api/line_items/clone";
export const CLONE_CREATIVE_ROUTE = "/api/creatives/clone";

export const CONTINENT_ROUTE = "/api/list/continents";

export const ACTIVITY_LOG_CAMPAIGN_ROUTE = "/api/campaigns/audit";
export const ACTIVITY_LOG_ADVERTISER_ROUTE = "/api/advertisers/audit";
export const ACTIVITY_LOG_LINE_ITEM_ROUTE = "/api/line_items/audit";
export const ACTIVITY_LOG_CREATIVE_ROUTE = "/api/creatives/audit";

export const EXT_CAMPAIGN_ROUTE = "/api/ext_campaigns";
export const EXT_CAMPAIGN_TYPES_ROUTE = "/api/list/ext_campaign_types";
export const EXT_CAMPAIGN_PROVIDERS_ROUTE = "/api/list/ext_campaign_providers";
export const EXT_BUY_MODELS_ROUTE = "/api/list/buy_models";
export const EXT_CAMPAIGN_MMP_ROUTE = "/api/list/ext_campaign_mmp";
export const EXT_CAMPAIGN_OWNERS_ROUTE = "/api/list/ext_campaign_owners";
export const EXT_CAMPAIGN_STATUS_ROUTE = "/api/list/ext_campaign_status";
export const EXT_CAMPAIGN_NOTES_ROUTE = "/api/ext_campaign_notes";
export const EXT_CAMPAIGN_CREATIVE_ROUTE = "/api/ext_creatives";
export const EXT_CAMPAIGN_CREATIVE_DISPLAY_ROUTE = "/api/ext_creatives_display";
export const EXT_CAMPAIGN_CREATIVE_VIDEO_ROUTE = "/api/ext_creatives_video";
export const EXT_CAMPAIGN_CREATIVE_NATIVE_ROUTE = "/api/ext_creatives_native";
export const EXT_CAMPAIGN_SEND_EMAIL_ROUTE = "api/ext_campaign_send_email";
export const EXT_CAMPAIGN_CREATIVE_SIZE_ROUTE = "/api/list/ext_creative_size";
export const EXT_CAMPAIGN_CREATIVE_ASPECT_RATIO_ROUTE = "/api/list/ext_creative_aspect_ratio";
export const EXT_CAMPAIGN_TYPES_CREATIVE_ROUTE = "/api/list/ext_creative_type";
export const NOTIFICATION_ROUTE = "/api/notifications";
export const NOTIFICATION_SETTINGS_ROUTE = "/api/notifications/settings";
export const CATCHUP_BEHAVIOUR_ROUTE = "api/list/catchup_behaviors";
export const NOTIFICATION_SET_ACTIVE = "/api/notifications/setActive";
export const NOTIFICATION_DISMISS = "/api/notifications/mark_read";
export const POLLS_ROUTE = "/api/polls";
export const POLL_RESPONSES_ROUTE = "/api/poll/responses";
export const POLL_QUESTIONS_ROUTE = "/api/polls/{poll_id}/questions";
export const POLL_CANCEL_ROUTE = "/api/poll/cancel";

export const TRANSLATION_LANGUAGES = "/api/list/translation_languages";

export const PLANNING = {
  PERSONS: {
    TAB_FILTERS: {
      COUNTRIES: "/api/personas/countries",
      STRATEGIES: "/api/personas/audience_strategy_types",
      USE_CASES: "/api/personas/audience_use_case_types",
      GEO: {
        STATES: "/api/personas/estados",
        CITIES: "/api/personas/municipios",
        NEIGHBORHOODS: "/api/personas/barrios",
      },
      POIS: {
        CATEGORIES: "/api/personas/categorias",
        SUBCATEGORIES: "/api/personas/subcategorias",
        BRANDS: "/api/personas/marcas",
        NAMES: "/api/personas/nombres",
      },
      PRIVATES: "/api/personas/private_pois",
      POST_PRIVATES: "/api/personas/private_pois_menu",
      OOH: {
        CATEGORIES: "/api/personas/ooh_categorias",
        SUBCATEGORIES: "/api/personas/ooh_subcategorias",
        BRANDS: "/api/personas/ooh_marcas",
        NAMES: "/api/personas/ooh_nombres",
      },
      POS: {
        CHIPPER_STATES: "/api/personas/chipper_estados",
        CHIPPER_CITIES: "/api/personas/chipper_municipios",
        CHIPPER_NEIGHBORHOODS: "/api/personas/chipper_barrios",
        CHIPPER_MACRO_CATEGORIES: "/api/personas/chipper_macro",
        CHIPPER_CATEGORIES: "/api/personas/chipper_categories",
        CHIPPER_COMPANIES: "/api/personas/chipper_company",
        CHIPPER_BRANDS: "/api/personas/chipper_brand",
        CHIPPER_NAMES_SKU: "/api/personas/chipper_name_sku",
        CHIPPER_STORES_TYPE: "/api/personas/chipper_store_type",
      },
    },
    ANALIZE: {
      POIS: "/api/personas/analyze_pois",
      GEO: "/api/personas/analyze_geo",
      POS: "/api/personas/analyze_chipper",
    },
    GRAPHIC: {
      GEO: {
        STATES: "/api/personas/analyze_geo/estados",
        CITIES: "/api/personas/analyze_geo/municipios",
        NEIGHBORHOODS: "/api/personas/analyze_geo/barrios",
      },
      POIS: {
        CATEGORIES: "/api/personas/analyze_pois/categorias",
        SUBCATEGORIES: "/api/personas/analyze_pois/subcategorias",
        BRANDS: "/api/personas/analyze_pois/marcas",
        STATES: "/api/personas/analyze_pois/estados",
        CITIES: "/api/personas/analyze_pois/municipios",
        NEIGHBORHOODS: "/api/personas/analyze_pois/barrios",
      },
    },
    CALCULATE: {
      POIS: "/api/personas/calculate_reach/pois",
      GEO: "/api/personas/calculate_reach/geo",
      POS: "/api/personas/calculate_reach/chipper",
      STORE_ATTRIBUTION: "/api/personas/calculate_reach/store_atr",
    },
    TOTAL_POIS: "/api/personas/total_pois",
    TOTAL_POIS_TYPE: "/api/personas/analyze_pois/types",
    SAVE_POIS: "/api/personas/private_pois/save_layer",
    EXPORT_LAT_LON_LIST: "/api/personas/export_lat_lon_list",
    LIST_TO_PRIVATE_POIS: "/api/personas/private_pois/list_to_private_poi",
    STORE_ATTRIBUTION: "/api/personas/store_atr/campaigns",
    ACTIVATE_OOH: "/api/personas/sent_notification_to_ooh",
    GET_TABLE_NAME: "/api/personas/get_table_name",
  },
  AUDIENCE: {
    POIS: {
      AUDIENCE: "/api/personas/pois_audience",
      DEMO_HOME_AUDIENCE: "/api/personas/pois_demo_home_audience",
      BID_REQUEST_AUDIENCE: "/api/personas/pois_bid_request_audience",
      ANALIZE_AUDIENCE: "/api/personas/analyze_audience",
      TOTAL_REACH: "/api/personas/audience/total_reach",
      PROXIMITY_TO_POI: "/api/personas/audience/proximity_to_poi",
      GENDER: "/api/personas/audience/gender",
      AGE: "/api/personas/audience/age",
      NIV_SOCIO_BR: "/api/personas/audience/niv_socio_br",
      NIV_SOCIO_ALL_EXP_BR: "/api/personas/audience/niv_socio_all_exp_br",
      CATEGORY_POI: "/api/personas/audience/category_poi",
      SUB_CATEGORY_POI: "/api/personas/audience/sub_category_poi",
      MARCA_POI: "/api/personas/audience/marca_poi",
      DPTO_POI: "/api/personas/audience/dpto_poi",
      CITY_POI: "/api/personas/audience/city_poi",
      BARRIO_POI: "/api/personas/audience/barrio_poi",
      DATE_OF_WEEK: "/api/personas/audience/date_of_week",
      TIME_OF_DAY: "/api/personas/audience/time_of_day",
      DATE: "/api/personas/audience/date",
      TIME_OF_DAY_OF_WEEK: "/api/personas/audience/time_of_day_of_week",
      RESIDENCE_DPTO: "/api/personas/audience/recidence_dpto",
      RESIDENCE_CITY: "/api/personas/audience/recidence_city",
      RESIDENCE_BARRIO: "/api/personas/audience/recidence_barrio",
      IAB: "/api/personas/audience/iab",
      INTEREST: "/api/personas/audience/interest",
      SITES: "/api/personas/audience/domain",
      APP_BUNDLE: "/api/personas/audience/app_bundle",
      APP_NAME: "/api/personas/audience/app_name",
      CONTENT_LANGUAGE: "/api/personas/audience/device_language",
      CITY_CONNECTION: "/api/personas/audience/city",
      CARRIER: "/api/personas/audience/carrier",
      DEVICE_TYPE: "/api/personas/audience/device_type",
      MAKE: "/api/personas/audience/device_make",
      BROWSER: "/api/personas/audience/browser",
      OS: "/api/personas/audience/os",
      TABLES: "/api/personas/audience/get_reports",
      // METRICS_BY_BRAND_AND_POI: "/api/personas/audience/metrics_by_brand_and_poi",
      // ATTRIBUTIONS: "/api/personas/audience/attributions",
      // RICH_ATTRIBUTIONS: "/api/personas/audience/rich_attributions",
      // RICH_ATTRIBUTIONS_DAILY: "/api/personas/analyze/rich_attributions"
    },
    GEO: {
      ANALIZE_AUDIENCE: "/api/personas/analyze_audience",
      DEMO_HOME_AUDIENCE: "/api/personas/geo_demo_home_audience",
      BID_REQUEST_AUDIENCE: "/api/personas/geo_bid_request_audience",
      TOTAL_REACH: "/api/personas/geo_audience/total_reach",
      PROXIMITY_TO_POI: "/api/personas/geo_audience/proximity_to_poi",
      GENDER: "/api/personas/geo_audience/gender",
      AGE: "/api/personas/geo_audience/age",
      NIV_SOCIO_BR: "/api/personas/geo_audience/niv_socio_br",
      NIV_SOCIO_ALL_EXP_BR: "/api/personas/geo_audience/niv_socio_all_exp_br",
      CATEGORY_POI: "/api/personas/geo_audience/category_poi",
      SUB_CATEGORY_POI: "/api/personas/geo_audience/sub_category_poi",
      MARCA_POI: "/api/personas/geo_audience/marca_poi",
      DPTO_POI: "/api/personas/geo_audience/metrics_dpto",
      CITY_POI: "/api/personas/geo_audience/metrics_city",
      BARRIO_POI: "/api/personas/geo_audience/metrics_barrio",
      DATE_OF_WEEK: "/api/personas/geo_audience/date_of_week",
      TIME_OF_DAY: "/api/personas/geo_audience/time_of_day",
      DATE: "/api/personas/geo_audience/date",
      TIME_OF_DAY_OF_WEEK: "/api/personas/geo_audience/time_of_day_of_week",
      RESIDENCE_DPTO: "/api/personas/geo_audience/recidence_dpto",
      RESIDENCE_CITY: "/api/personas/geo_audience/recidence_city",
      RESIDENCE_BARRIO: "/api/personas/geo_audience/recidence_barrio",
      IAB: "/api/personas/geo_audience/iab",
      INTEREST: "/api/personas/geo_audience/interest",
      SITES: "/api/personas/geo_audience/domain",
      APP_BUNDLE: "/api/personas/geo_audience/app_bundle",
      APP_NAME: "/api/personas/geo_audience/app_name",
      CONTENT_LANGUAGE: "/api/personas/geo_audience/device_language",
      CITY_CONNECTION: "/api/personas/geo_audience/city",
      CARRIER: "/api/personas/geo_audience/carrier",
      DEVICE_TYPE: "/api/personas/geo_audience/device_type",
      MAKE: "/api/personas/geo_audience/device_make",
      BROWSER: "/api/personas/geo_audience/browser",
      OS: "/api/personas/geo_audience/os",
      TABLES: "/api/personas/geo_audience/get_reports",
      // METRICS_BY_BRAND_AND_POI: "/api/personas/audience/metrics_by_brand_and_poi",
      // ATTRIBUTIONS: "/api/personas/audience/attributions",
      // RICH_ATTRIBUTIONS: "/api/personas/audience/rich_attributions",
      // RICH_ATTRIBUTIONS_DAILY: "/api/personas/analyze/rich_attributions"
    },
    POS: {
      ANALIZE_AUDIENCE: "/api/personas/analyze_audience",
      DEMO_HOME_AUDIENCE: "/api/personas/chiper_demo_home_audience",
      BID_REQUEST_AUDIENCE: "/api/personas/chiper_bid_request_audience",
      TOTAL_REACH: "/api/personas/geo_audience/total_reach",
      PROXIMITY_TO_POI: "/api/personas/geo_audience/proximity_to_poi",
      GENDER: "/api/personas/geo_audience/gender",
      AGE: "/api/personas/geo_audience/age",
      NIV_SOCIO_BR: "/api/personas/geo_audience/niv_socio_br",
      NIV_SOCIO_ALL_EXP_BR: "/api/personas/geo_audience/niv_socio_all_exp_br",
      CATEGORY_POI: "/api/personas/geo_audience/category_poi",
      SUB_CATEGORY_POI: "/api/personas/geo_audience/sub_category_poi",
      MARCA_POI: "/api/personas/geo_audience/marca_poi",
      DPTO_POI: "/api/personas/geo_audience/metrics_dpto",
      CITY_POI: "/api/personas/geo_audience/metrics_city",
      BARRIO_POI: "/api/personas/geo_audience/metrics_barrio",
      DATE_OF_WEEK: "/api/personas/geo_audience/date_of_week",
      TIME_OF_DAY: "/api/personas/geo_audience/time_of_day",
      DATE: "/api/personas/geo_audience/date",
      TIME_OF_DAY_OF_WEEK: "/api/personas/geo_audience/time_of_day_of_week",
      RESIDENCE_DPTO: "/api/personas/geo_audience/recidence_dpto",
      RESIDENCE_CITY: "/api/personas/geo_audience/recidence_city",
      RESIDENCE_BARRIO: "/api/personas/geo_audience/recidence_barrio",
      IAB: "/api/personas/geo_audience/iab",
      INTEREST: "/api/personas/geo_audience/interest",
      SITES: "/api/personas/geo_audience/domain",
      APP_BUNDLE: "/api/personas/geo_audience/app_bundle",
      APP_NAME: "/api/personas/geo_audience/app_name",
      CONTENT_LANGUAGE: "/api/personas/geo_audience/device_language",
      CITY_CONNECTION: "/api/personas/geo_audience/city",
      CARRIER: "/api/personas/geo_audience/carrier",
      DEVICE_TYPE: "/api/personas/geo_audience/device_type",
      MAKE: "/api/personas/geo_audience/device_make",
      BROWSER: "/api/personas/geo_audience/browser",
      OS: "/api/personas/geo_audience/os",
      TABLES: "/api/personas/geo_audience/get_reports",
    },
    FILTER_GRAPHIC: "/api/personas/filter_audience",
    GET_FILTER: "/api/personas/audience/get_filter",
    DOWNLOAD_METRICS: "/api/personas/download_demographics_by_poi",
    DOWNLOAD_ATTRIBUTIONS: "/api/personas/download_matrix_poi",
    DOWNLOAD_RICH_ATTRIBUTIONS: "/api/personas/download_visits_by_distance_by_poi",
    DOWNLOAD_RICH_ATTRIBUTIONS_DAILY: "/api/personas/download_rich_attribution",
    CREATE_SEGMENT: "/api/personas/create_segment",
    AUDIENCE_TYPE: "/api/personas/audience_type",
    CAN_FILTER_READY: "/api/personas/filter_audience_ready",
  },
} as PlanningV10;

