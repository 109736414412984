import { TermValue } from "@/models/ItemEntity";
import { ModifierForm } from "@/models/Modifiers/form";

export interface Modifier {
  id: number;
  external_id: number;
  alternative_id: number;
  account_id: number;
  advertiser_id: number;
  name: string;
  modifier_type_id: number;
  active: boolean;
  created_by: number;
  updated_by: number;
  deleted_by: string;
  created_at: string;
  updated_at: string;
  advertiser: {
    id: number;
    external_id: number;
    account_id: number;
    name: string;
    domain: string;
    category_id: number;
    app_bundle: string;
    currency_id: number;
    active: boolean;
    created_by: number;
    updated_by: number;
    deleted_by: string;
    created_at: string;
    updated_at: string;
  };
  type: {
    id: number;
    type: string;
    description: string;
    extra: string;
  };
  terms?: ModifierTerm[];
}

export interface ModifierDataCreate {
  name: string;
  advertiser_id: number;
  alternative_id: number;
  modifier_type_id: number;
  terms: ModifierTermData[];
}

export interface ModifierDataUpdate {
  id: number;
  name: string;
  alternative_id: number;
  terms: ModifierTermData[];
}

export interface ModifierTermData {
  module_id: number;
  key: string;
  value: string;
  modifier: number;
  matching_type_id: number;
  override_multiplier?: 0 | 1;
}

export interface ModifierTerm {
  id: number;
  modifier_id: number;
  module_id: number;
  key: string;
  value: string;
  modifier: number;
  matching_type_id: number;
  active: boolean;
  created_by?: number;
  updated_by?: number;
  deleted_by?: string;
  created_at?: string;
  updated_at?: string;
  module?: {
    id: number;
    type: string;
    description: string;
    extra: string;
  };
  matching_type?: {
    id: number;
    type: string;
    description: string;
    extra: string;
  };
}

export interface ModifierType {
  id: number;
  name: string;
}

export interface ModifierModule {
  id: number;
  name: string;
}

export interface ModifierDataUpdate {
  id: number;
  name: string;
  category_id: number;
  domain: string;
  app_bundle: string;
  active: boolean;
}

export interface ModifierFilters {
  id?: number;
  name?: string;
  alternative_id?: number;
  external_id?: number;
  advertiser_id?: number;
  modifier_type_id?: number;
  active?: boolean;
  modifier_name?: string;
  advertiser_name?: string; //Advertiser Test Polack
  updated_at?: string; // >= 2022-01-01
  type_description?: string;
  modifier_type_extra: string;
}

export interface ModifierOptions {
  sort: string; // name | domain | app_bundle | external_id
  order: string; // asc | desc
}

export interface ModifierPaginated {
  page: number;
  limit: number;
}

export interface ModifierList {
  id: number;
  value: string;
}

export interface ResultPaginate {
  current_page?: number;
  data?: Modifier[];
  first_page_url?: string;
  from?: number;
  last_page?: number;
  last_page_url?: string;
  next_page_url?: string;
  path?: string;
  per_page?: number;
  prev_page_url?: string;
  to?: number;
  total?: number;
}

export interface ModifiersOptions {
  key: any;
  value: any;
  value_name: string;
  module_id: number | undefined;
  module_name: string;
  matching_type_id: number | undefined;
  matching_type_name: string;
  override_multiplier?: number | undefined;
}

// INTERFACES Y CLASES PARA DATOS OBTENIDOS DESDE API

interface IAdvertiser {
  id: number;
  external_id: number;
  account_id: number;
  name: string;
  domain: string;
  category_id: number;
  app_bundle: string | null;
  currency_id: number;
  active: boolean;
  created_by: number;
  updated_by: number;
  deleted_by: number | null;
  created_at: string;
  updated_at: string;
  bidding_status: any;
  winning_status: any;
  impression: any;
  clicks: any;
  cpm: any;
  cpc: any;
  ctr: any;
  conversion_order: any;
  conversion_value: any;
  conversion_count: any;
  vcpm: any;
  video_complete_percent: any;
  video_completes: any;
  video_plays: any;
  viewable_impressions: any;
  today_viewable_percent: any;
  maker_name: string | null;
  country_id: number | null;
  maker_id: number | null;
  trademark_id: number | null;
}

export class Advertiser implements IAdvertiser {
  id: number;
  external_id: number;
  account_id: number;
  name: string;
  domain: string;
  category_id: number;
  app_bundle: string | null;
  currency_id: number;
  active: boolean;
  created_by: number;
  updated_by: number;
  deleted_by: number | null;
  created_at: string;
  updated_at: string;
  bidding_status: any;
  winning_status: any;
  impression: any;
  clicks: any;
  cpm: any;
  cpc: any;
  ctr: any;
  conversion_order: any;
  conversion_value: any;
  conversion_count: any;
  vcpm: any;
  video_complete_percent: any;
  video_completes: any;
  video_plays: any;
  viewable_impressions: any;
  today_viewable_percent: any;
  maker_name: string | null;
  country_id: number | null;
  maker_id: number | null;
  trademark_id: number | null;

  constructor(payload?: Partial<IAdvertiser>) {
    this.id = payload?.id ?? 0;
    this.external_id = payload?.external_id ?? 0;
    this.account_id = payload?.account_id ?? 0;
    this.name = payload?.name ?? "";
    this.domain = payload?.domain ?? "";
    this.category_id = payload?.category_id ?? 0;
    this.app_bundle = payload?.app_bundle ?? null;
    this.currency_id = payload?.currency_id ?? 0;
    this.active = payload?.active ?? false;
    this.created_by = payload?.created_by ?? 0;
    this.updated_by = payload?.updated_by ?? 0;
    this.deleted_by = payload?.deleted_by ?? null;
    this.created_at = payload?.created_at ?? "";
    this.updated_at = payload?.updated_at ?? "";
    this.bidding_status = payload?.bidding_status ?? null;
    this.winning_status = payload?.winning_status ?? null;
    this.impression = payload?.impression ?? null;
    this.clicks = payload?.clicks ?? null;
    this.cpm = payload?.cpm ?? null;
    this.cpc = payload?.cpc ?? null;
    this.ctr = payload?.ctr ?? null;
    this.conversion_order = payload?.conversion_order ?? null;
    this.conversion_value = payload?.conversion_value ?? null;
    this.conversion_count = payload?.conversion_count ?? null;
    this.vcpm = payload?.vcpm ?? null;
    this.video_complete_percent = payload?.video_complete_percent ?? null;
    this.video_completes = payload?.video_completes ?? null;
    this.video_plays = payload?.video_plays ?? null;
    this.viewable_impressions = payload?.viewable_impressions ?? null;
    this.today_viewable_percent = payload?.today_viewable_percent ?? null;
    this.maker_name = payload?.maker_name ?? null;
    this.country_id = payload?.country_id ?? null;
    this.maker_id = payload?.maker_id ?? null;
    this.trademark_id = payload?.trademark_id ?? null;
  }
}

export interface IModifierType {
  id: number;
  type: string;
  description: string;
  extra: string;
  active: boolean;
}

export class ModifierType implements IModifierType {
  id: number;
  type: string;
  description: string;
  extra: string;
  active: boolean;

  constructor(payload?: Partial<IModifierType>) {
    this.id = payload?.id ?? 0;
    this.type = payload?.type ?? "";
    this.description = payload?.description ?? "";
    this.extra = payload?.extra ?? "";
    this.active = payload?.active ?? true;
  }
}

export interface IBidTerm {
  id: number;
  modifier_id: number;
  module_id: number;
  key: string;
  value: string;
  modifier: number;
  matching_type_id: number;
  override_multiplier: number | undefined;
  active: boolean;
  created_by: number;
  updated_by: number;
  deleted_by: number | null;
  created_at: string;
  updated_at: string;
  value_name: string;
}

export class DeliveryTermTargeting {
  key: string;
  value: string;
  value_name: string;

  constructor(payload?: Partial<DeliveryTermTargeting>) {
    this.key = payload?.key ?? "";
    this.value = payload?.value ?? "";
    this.value_name = payload?.value_name ?? "";
  }
}

export class DeliveryTerm {
  id: number;
  modifier_id: number;
  targeting: DeliveryTermTargeting[];
  weight: number;
  rank: number;
  budget_cap: any | null;
  created_by: number;
  updated_by: number;
  deleted_by: number | null;
  created_at: string;
  updated_at: string;

  constructor(payload?: Partial<DeliveryTerm>) {
    this.id = payload?.id ?? 0;
    this.modifier_id = payload?.modifier_id ?? 0;
    this.targeting = payload?.targeting?.map(t => new DeliveryTermTargeting(t)) ?? [];
    this.weight = payload?.weight ?? 0;
    this.rank = payload?.rank ?? 0;
    this.budget_cap = payload?.budget_cap ?? null;
    this.created_by = payload?.created_by ?? 0;
    this.updated_by = payload?.updated_by ?? 0;
    this.deleted_by = payload?.deleted_by ?? null;
    this.created_at = payload?.created_at ?? "";
    this.updated_at = payload?.updated_at ?? "";
  }
}

export class BidTerm implements IBidTerm {
  id: number;
  modifier_id: number;
  module_id: number;
  key: string;
  value: string;
  modifier: number;
  matching_type_id: number;
  override_multiplier: number | undefined;
  active: boolean;
  created_by: number;
  updated_by: number;
  deleted_by: number | null;
  created_at: string;
  updated_at: string;
  value_name: string;

  constructor(payload?: Partial<IBidTerm>) {
    this.id = payload?.id ?? 0;
    this.modifier_id = payload?.modifier_id ?? 0;
    this.module_id = payload?.module_id ?? 0;
    this.key = payload?.key ?? "";
    this.value = payload?.value ?? "";
    this.modifier = payload?.modifier ?? 0;
    this.matching_type_id = payload?.matching_type_id ?? 0;
    this.override_multiplier = payload?.override_multiplier;
    this.active = payload?.active ?? true;
    this.created_by = payload?.created_by ?? 0;
    this.updated_by = payload?.updated_by ?? 0;
    this.deleted_by = payload?.deleted_by ?? 0;
    this.created_at = payload?.created_at ?? "";
    this.updated_at = payload?.updated_at ?? "";
    this.value_name = payload?.value_name ?? "";
  }
}

export class ModifierFromAPI {
  id: number;
  external_id?: number;
  alternative_id: number | null;
  account_id: number;
  advertiser_id: number;
  name: string;
  modifier_type_id: number;
  active: boolean;
  fallback_weight: number | null;
  fallback_cap: number | null;
  created_by: number;
  updated_by: number;
  deleted_by: number | null;
  created_at: string;
  updated_at: string;
  bid_model_id: number | null;
  advertiser: Advertiser | null;
  type: ModifierType | null;
  bid_terms: BidTerm[];
  delivery_terms: DeliveryTerm[];

  constructor(payload?: Partial<ModifierFromAPI>) {
    this.id = payload?.id ?? 0;
    this.external_id = payload?.external_id;
    this.alternative_id = payload?.alternative_id ?? null;
    this.account_id = payload?.account_id ?? 0;
    this.advertiser_id = payload?.advertiser_id ?? 0;
    this.name = payload?.name ?? "";
    this.modifier_type_id = payload?.modifier_type_id ?? 0;
    this.active = payload?.active ?? false;
    this.fallback_weight = payload?.fallback_weight ?? null;
    this.fallback_cap = payload?.fallback_cap ?? null;
    this.created_by = payload?.created_by ?? 0;
    this.updated_by = payload?.updated_by ?? 0;
    this.deleted_by = payload?.deleted_by ?? null;
    this.created_at = payload?.created_at ?? "";
    this.updated_at = payload?.updated_at ?? "";
    this.bid_model_id = payload?.bid_model_id ?? null;
    this.advertiser = payload?.advertiser ? new Advertiser(payload.advertiser) : null;
    this.type = payload?.type ? new ModifierType(payload.type) : null;
    this.bid_terms = payload?.bid_terms?.map(term => new BidTerm(term)) ?? [];
    this.delivery_terms = payload?.delivery_terms?.map(term => new DeliveryTerm(term)) ?? [];
  }

  toModifierForm(): ModifierForm {
    const typeId = this.type?.id ?? "";
    const typeValue = this.type?.description.toString() ?? "";
    const newModifierType: TermValue | undefined = !!this.type ? new TermValue(typeId, typeValue) : undefined;

    return new ModifierForm({
      id: this.id,
      external_id: this.external_id,
      name: this.name,
      advertiser_id: this.advertiser_id,
      alternative_id: this.alternative_id ?? undefined,
      modifier_type_id: this.modifier_type_id,
      modifier_type: newModifierType,
      fallback_weight: this.fallback_weight?.toString() ?? "1",
      active: this.active,
      bid_model_id: this.bid_model_id ?? undefined,
      terms: this.bid_terms,
      delivery_terms: this.delivery_terms,
    });
  }
}

export class PayloadModifierDispatch {
  id?: number;
  modifier: ModifierForm;
  isBidModifier: boolean;
  isDeliveryModifier: boolean;

  constructor(payload?: Partial<PayloadModifierDispatch>) {
    this.id = payload?.id ? Number(payload?.id) : undefined;
    this.modifier = new ModifierForm(payload?.modifier);
    this.isBidModifier = payload?.isBidModifier ?? false;
    this.isDeliveryModifier = payload?.isDeliveryModifier ?? false;
  }

  getId() {
    return Number(this.id);
  }
}
