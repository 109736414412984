import { ModifierOption, ModifierOptionConfig, ModifierOptionKey } from "@/models/Modifiers/Terms/index";
import { MODIFIER_OPTION_ENDPOINT_KEYS } from "@/services/Modifiers/const";
import ModifierTermService from "@/services/Modifiers/term-service";
import { FetchConfigParams, ModifierOptionEndpointKey } from "@/services/Modifiers/types";
import { catchError } from "@/store/Util";

export const ModifierTermModule = {
  namespaced: true,
  state: () => ({
    modifierOptions: new ModifierOption(),
  }),
  mutations: {
    SET_MODIFIER_OPTIONS(state: any, modifierOptions: Record<ModifierOptionKey, Record<string, ModifierOptionConfig>>) {
      state.modifierOptions = new ModifierOption(modifierOptions);
    },
  },
  getters: {
    getterModifierOptions(state: { modifierOptions: ModifierOption }): ModifierOption {
      return state.modifierOptions;
    },
    getModifierOptionsWith:
			(state: { modifierOptions: ModifierOption }) =>
			(key: ModifierOptionKey, isDinamic: boolean) => {
				return state.modifierOptions.getFilteredItems(key, isDinamic);
			},

      
    isOptionOverride:
    (state: { modifierOptions: ModifierOption }) =>
    (key: ModifierOptionKey, nestedKey: string) => {
      return state.modifierOptions.isItemOverride(key, nestedKey);
    }, 
  },
  actions: {
    async resetModifierOptions({ commit }) {
      try {
        commit("SET_MODIFIER_OPTIONS", new ModifierOption());
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },

    async fetchDataGet({ commit }, key: ModifierOptionEndpointKey) {
      try {
        const response = await ModifierTermService.fetchGetData(key);

        if (MODIFIER_OPTION_ENDPOINT_KEYS.includes(key)) {
          commit("SET_MODIFIER_OPTIONS", response);
        }

        return await Promise.resolve(response);
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },

    async searchDataByTerm(
      { commit },
      params: {
        key: string;
        term: string;
      },
    ) {
      try {
        const response = await ModifierTermService.fetchSearchByTerm(params);
        return await Promise.resolve(response);
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },

    async fetchWithConfig({ commit }, params: FetchConfigParams) {
      try {
        const response = await ModifierTermService.fetchWithConfig(params);
        return await Promise.resolve(response);
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
  },
};
