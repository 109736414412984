import { CustomListPaginated, CustomListFilters, CustomListOptions } from "@/interfaces/custom_list";
import { AxiosGet, GetData } from "@/services/axios-service";
import { isUndefined, isEmpty } from "lodash";
import { catchServiceErrors } from "@/utils/services-global";
import ROUTES from "@/api/routes";

// Endpoint to Global Parameters
export const MATCHING_TYPE_LIST_ROUTE = "/api/list/matching_types";

// Endpoint to module --> GEO
export const COUNTRY_LIST_ROUTE = "/api/list/countries";
export const REGION_LIST_ROUTE = "/api/list/regions";
export const CITY_LIST_ROUTE = "/api/list/cities";
export const LOCATION_LIST_ROUTE = "/api/list/targeting_location_types";
export const LAT_LONG_LIST_ROUTE = "/api/list/custom_lists/lat_long";
// Endpoint to module --> AD SIZES
export const SIZE_LIST_ROUTE = "/api/list/creative_sizes";
// Endpoint to module --> INVENTORY
export const POSITION_LIST_ROUTE = "/api/list/ad_positions";
export const AUCTION_TYPES_LIST_ROUTE = "/api/list/auction_types";
export const DEAL_ID_LIST_ROUTE = "/api/list/custom_lists/deal_id";
export const ENVIRONMENT_TYPES_LIST_ROUTE = "/api/list/environment_types";
export const INTERSTITIAL_FLAGS_LIST_ROUTE = "/api/list/interstitial_flags";
export const INTERSTITIAL_TYPES_LIST_ROUTE = "/api/list/interstitial_types";
export const INVENTORY_SOURCE_LIST_ROUTE = "/api/list/inventory_sources";
export const PLACEMENT_IDS_LIST_ROUTE = "/api/list/custom_lists/placement_id";
export const PUBLISHER_IDS_LIST_ROUTE = "/api/list/custom_lists/publisher_id";
export const SITES_LIST_ROUTE = "/api/list/custom_lists/site_id";
// Endpoint to module --> CONTENT
export const CONTENT_CATEGORY_LIST_ROUTE = "/api/list/content_categories";
export const CONTENT_RATING_LIST_ROUTE = "/api/list/content_ratings";
export const LANGUAGES_LIST_ROUTE = "/api/list/languages";
// Endpoint to module --> VIDEO
export const COMPANION_SUPPORT_LIST_ROUTE = "/api/list/companion_support";
export const VIDEO_PLAYBACK_METHODS_LIST_ROUTE = "/api/list/video_playback_methods";
export const PLAYER_SIZE_LIST_ROUTE = "/api/list/video_player_sizes";
export const START_DELAY_LIST_ROUTE = "/api/list/video_start_delays";
export const VIDEO_PLACEMENT_LIST_ROUTE = "/api/list/video_placement_types";
// Endpoint to module --> MOBILE APP
export const APP_BUNDLE_LIST_ROUTE = "/api/list/custom_lists/app_bundle";
export const APP_ID_LIST_ROUTE = "/api/list/custom_lists/app_id";
// Endpoint to module --> PLATFORM
export const BANDWIDTH_LIST_ROUTE = "/api/list/bandwidths";
export const BROWSER_LIST_ROUTE = "/api/list/browsers";
export const BROWSER_VERSION_LIST_ROUTE = "/api/list/browser_versions";
export const CARRIER_LIST_ROUTE = "/api/list/carriers";
export const DEVICE_MAKE_LIST_ROUTE = "/api/list/device_makes";
export const DEVICE_MODEL_LIST_ROUTE = "/api/list/device_models";
export const DEVICE_SCREEN_SIZE_LIST_ROUTE = "/api/list/device_screen_sizes";
export const DEVICE_TYPE_LIST_ROUTE = "/api/list/devices";
export const OPERATING_SYSTEM_LIST_ROUTE = "/api/list/operating_systems";
export const OPERATING_SYSTEM_VERSION_LIST_ROUTE = "/api/list/operating_system_versions";
export const DOMAIN_LIST_ROUTE = "/api/list/custom_lists/domain";
export const IP_ADDRESS_LIST_ROUTE = "/api/list/custom_lists/ip_address";

// Endpoint to module -->
export const VIDEO_APIS_LIST_ROUTE = "/api/list/video_apis";
export const NATIVE_LAYOUT_LIST_ROUTE = "/api/list/native_layouts";
export const SEGMENT_PROVIDER_LIST_ROUTE = "/api/list/segment_providers";
export const SEGMENT_CATEGORY_LIST_ROUTE = "/api/list/segment_categories";

//

const ROUTE = ROUTES.MODIFIERS;
const ROUTE_APP_SITE = ROUTES.APP_SITE;
const ROUTE_ENVIRONMENT = ROUTES.ENVIRONMENT;

class ModifierOptionsService {
  // GLOBAL PARAMETERS
  async matchingTypeList(params: any) {
    try {
      const response = await AxiosGet(`${MATCHING_TYPE_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  // MODULE GEO
  async countriesList(params: any) {
    try {
      const response = await AxiosGet(`${COUNTRY_LIST_ROUTE}?term=` + params.term);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async regionsList(params: any) {
    try {
      const response = await AxiosGet(`${REGION_LIST_ROUTE}?term=` + params.term);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async citiesList(params: any) {
    try {
      const response = await AxiosGet(`${CITY_LIST_ROUTE}?term=` + params.term);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async locationsList() {
    try {
      const response = await AxiosGet(`${LOCATION_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async latLongList() {
    try {
      const response = await AxiosGet(`${LAT_LONG_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  // MODULE AD SIZE
  async sizesList() {
    try {
      const response = await AxiosGet(`${SIZE_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  // MODULE INVENTORY
  async positionsList() {
    try {
      const response = await AxiosGet(`${POSITION_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async auctionTypesList() {
    try {
      const response = await AxiosGet(`${AUCTION_TYPES_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async dealIdList() {
    try {
      const response = await AxiosGet(`${DEAL_ID_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async environmentTypesList() {
    try {
      const response = await AxiosGet(`${ENVIRONMENT_TYPES_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async interstitialFlagsList() {
    try {
      const response = await AxiosGet(`${INTERSTITIAL_FLAGS_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async interstitialTypesList() {
    try {
      const response = await AxiosGet(`${INTERSTITIAL_TYPES_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async inventorySourceList() {
    try {
      const response = await AxiosGet(`${INVENTORY_SOURCE_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async placementIdList() {
    try {
      const response = await AxiosGet(`${PLACEMENT_IDS_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async publisherIdList() {
    try {
      const response = await AxiosGet(`${PUBLISHER_IDS_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async siteIdList() {
    try {
      const response = await AxiosGet(`${SITES_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async APIFrameworksList() {
    try {
      const response = await AxiosGet(`${VIDEO_APIS_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async APITopFrame() {
    try {
      const response = await AxiosGet(ROUTE_ENVIRONMENT.ENVIRONMENT_TPFRAME_ROUTE);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  // MODULE CONTENT
  async contentCategoriesList() {
    try {
      const response = await AxiosGet(`${CONTENT_CATEGORY_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async contentRatingsList() {
    try {
      const response = await AxiosGet(`${CONTENT_RATING_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async languagesList() {
    try {
      const response = await AxiosGet(`${LANGUAGES_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  // MODULE VIDEO
  async companionSupportsList() {
    try {
      const response = await AxiosGet(`${COMPANION_SUPPORT_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async videoPlaybackMethodsList() {
    try {
      const response = await AxiosGet(`${VIDEO_PLAYBACK_METHODS_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async playerSizesList() {
    try {
      const response = await AxiosGet(`${PLAYER_SIZE_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async startDelaysList() {
    try {
      const response = await AxiosGet(`${START_DELAY_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async videoPlacementsList() {
    try {
      const response = await AxiosGet(`${VIDEO_PLACEMENT_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  // MODULE MOBILE APP
  async appBundlesList() {
    try {
      const response = await AxiosGet(`${APP_BUNDLE_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async appIdsList() {
    try {
      const response = await AxiosGet(`${APP_ID_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }
  // MODULE NATIVE
  async nativeLayoutsList() {
    try {
      const response = await AxiosGet(`${NATIVE_LAYOUT_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  // MODULE PLATFORM
  async bandwidthList() {
    try {
      const response = await AxiosGet(`${BANDWIDTH_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async browserList() {
    try {
      const response = await AxiosGet(`${BROWSER_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async browserVersionList() {
    try {
      const response = await AxiosGet(`${BROWSER_VERSION_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async carrierList() {
    try {
      const response = await AxiosGet(`${CARRIER_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async deviceMakeList() {
    try {
      const response = await AxiosGet(`${DEVICE_MAKE_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async deviceModelList() {
    try {
      const response = await AxiosGet(`${DEVICE_MODEL_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async deviceScreenSizeList() {
    try {
      const response = await AxiosGet(`${DEVICE_SCREEN_SIZE_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async deviceTypeList() {
    try {
      const response = await AxiosGet(`${DEVICE_TYPE_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async operatingSystemList() {
    try {
      const response = await AxiosGet(`${OPERATING_SYSTEM_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async OperatingSystemVersionList() {
    try {
      const response = await AxiosGet(`${OPERATING_SYSTEM_VERSION_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  // MODULE DOMAIN
  async domainList() {
    try {
      const response = await AxiosGet(`${DOMAIN_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  // MODULE IP ADDRESS LIST
  async ipAddressList() {
    try {
      const response = await AxiosGet(`${IP_ADDRESS_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  // MODULE
  async videoAPIsList() {
    try {
      const response = await AxiosGet(`${VIDEO_APIS_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async segmentProvidersList() {
    try {
      const response = await AxiosGet(`${SEGMENT_PROVIDER_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async segmentCategoriesList() {
    try {
      const response = await AxiosGet(`${SEGMENT_CATEGORY_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async getAppName() {
    try {
      const response = await AxiosGet(ROUTE_APP_SITE.APP_NAME_ROUTE);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }
}

function getFilters(filters: CustomListFilters): string {
  let filter = "";

  const id = !!filters.id ? filters.id : "";
  const name = !!filters.name ? filters.name : "";
  const type = !!filters.type_name ? filters.type_name : "";
  const active = typeof filters.active === "undefined" ? "" : filters.active;

  filter +=
    "filters[custom_lists.id]=" +
    id +
    "&filters[custom_lists.name]=" +
    name +
    "&filters[list_types.name]=" +
    type +
    "&filters[custom_lists.active]=" +
    active;

  return filter;
}

function getOptions(options: CustomListOptions, mode: string, paginated?: CustomListPaginated): string {
  let option = "";

  const sort = isUndefined(options.sort) ? "" : options.sort;
  const order = isUndefined(options.order) ? "" : options.order;

  option += "sort=" + sort + "&order=" + order + "&mode=" + mode;

  if (mode == "paginated") {
    option += "&page=" + paginated?.page + "&limit=" + paginated?.limit;
  }

  return option;
}

function getURL(filters: string, options: string): string {
  let url = "";

  if (!isEmpty(filters) && !isEmpty(options)) {
    url = "?" + filters + "&" + options;
  } else if (!isEmpty(filters)) {
    url = "?" + filters;
  } else if (!isEmpty(options)) {
    url = "?" + options;
  }

  return url;
}

export default new ModifierOptionsService();
