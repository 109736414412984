export type AlertMessageType = "exist" | "required" | "max";
export type AlertType = "success" | "info" | "warning" | "error";

export class AlertMessage {
  show: boolean;
  message: string;
  key: AlertMessageType;
  type: AlertType;
  dismissible?: boolean | undefined;

  constructor(payload?: Partial<AlertMessage>) {
    this.show = payload?.show ?? false;
    this.message = payload?.message ?? "";
    this.key = payload?.key ?? "exist";
    this.type = payload?.type ?? "info";
    this.dismissible = payload?.dismissible ?? true;
  }

  setVisible(show: boolean) {
    this.show = show;
  }
}
