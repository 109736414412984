import { AppSite } from "@/interfaces/appSite";
import { SortingOption } from "@/interfaces/paginated";
import { TargetingFilters } from "@/interfaces/targeting";
import TargetingService from "@/services/targeting-service";
import { CatcherError, catchError } from "@/store/Util";
import { resolveList, resolveListParams } from "@/utils/resolveObjectArray";
import notificationService from "@/services/notification-service";
import { Notification } from "@/interfaces/proccess";
import { ItemBuilder } from "@/models/ItemEntity";

export const TargetingModule = {
  namespaced: true,
  state: () => ({
    app_sites: [] as Array<AppSite>,
    targeting_expressions: null,
    targeting_summary: {},
  }),
  mutations: {
    SET_APP_SITES(state, _app_sites: Array<AppSite>) {
      state.app_sites = _app_sites;
    },
    SET_TARGETING_EXPRESSIONS(state, _targeting_expressions: any) {
      state.targeting_expressions = _targeting_expressions;
    },
    SET_SUMMARY_TARGETING(state, targeting_summary: any) {
      state.targeting_summary = targeting_summary;
    },
  },
  getters: {
    getTargetingSummary(state) {
      return state.targeting_summary;
    },
  },
  actions: {
    async getTargetingKeys({}) {
      try {
        const response = await TargetingService.getTargetingKeys();
        return await Promise.resolve(response);
      } catch (error) {
        CatcherError(this.dispatch, error);
        return await Promise.reject(error);
      }
    },
    async getTargetingKey(
      { commit },
      params: {
        key: string;
        object?: { key: string; value: string };
      },
    ) {
      try {
        const response = await TargetingService.getTargetingKey(params.key);

        // Mapeo de keys a métodos correspondientes
        const methodMap: { [key: string]: Function } = {
          interstitial_type: () => new ItemBuilder().fromObject(response, true, true).build(),
          default: () =>
            resolveListParams(response, params.object?.key ?? "external_id", params.object?.value ?? "name"),
        };

        // Ejecutar el método correspondiente basado en params.key
        return Promise.resolve(methodMap[params.key] ? methodMap[params.key]() : methodMap.default());
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async getAppNameByAtribute({ commit }, params) {
      try {
        const response = await TargetingService.getAppNameByAtribute(params.term, params.by_attribute);
        return await Promise.resolve(
          resolveListParams(response, params.object?.key || "id", params.object?.value || "app_name"),
        );
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },

    /**
     * Realiza una búsqueda de elementos basada en un término específico.
     * @param params - Parámetros de la búsqueda.
     * @returns - Lista de resultados obtenidos de la API.
     **/
    async getSearchByTerm(
      { commit },
      params: {
        key: string;
        term: string;
        object?: { key: string; value: string };
        by_attribute?: any;
        filters?: Record<string, any>;
      },
    ): Promise<any[]> {
      try {
        const response = await TargetingService.getSearchByTerm(params);
        return await Promise.resolve(
          resolveListParams(response, params.object?.key || "external_id", params.object?.value || "name", false, true),
        );
      } catch (error) {
        CatcherError(this.dispatch, error);
        return await Promise.reject(error);
      }
    },
    async getSitesByAtribute({ commit }, params) {
      try {
        const response = await TargetingService.getSitesByAtribute(params.term, params.by_attribute);
        return await Promise.resolve(resolveListParams(response, "site_id", "site_id"));
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },

    async all(
      { commit },
      params: {
        filters: TargetingFilters;
        options: SortingOption;
        formatter: Boolean;
      },
    ) {
      try {
        const response = await TargetingService.all(params.filters, params.options, params.formatter);

        if (params.formatter) {
          commit("SET_TARGETING_EXPRESSIONS", response);
        }
        return await Promise.resolve(response);
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },

    async create({ commit }, targeting) {
      try {
        const response = await TargetingService.create(targeting);
        notificationService.notifySuccess(this, {
          to: "",
        } as Notification);
        return await Promise.resolve(response);
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },

    async update({ commit }, targeting) {
      try {
        const response = await TargetingService.update(targeting);
        notificationService.notifySuccess(this, {
          to: "",
        } as Notification);
        return await Promise.resolve(response);
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },

    async getTargetingPredicates({ commit }) {
      try {
        const response = await TargetingService.getTargetingPredicates();
        return await Promise.resolve(resolveList(response));
      } catch (error) {
        CatcherError(this.dispatch, error);
        return await Promise.reject(error);
      }
    },

    async fetchSummaryTargeting({ commit }, params) {
      try {
        const response = await TargetingService.getSummaryTargeting(params.id);
        commit("SET_SUMMARY_TARGETING", response);
        return await Promise.resolve(response);
      } catch (error) {
        CatcherError(this.dispatch, error);
        return await Promise.reject(error);
      }
    },
  },
};
