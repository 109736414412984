import { AppName, AppSite, Site } from "@/interfaces/appSite";
import { Paginated, SortingOption } from "@/interfaces/paginated";
import {
  SearchByTermParams,
  Targeting,
  TargetingDataCreate,
  TargetingDataUpdate,
  TargetingFilters,
} from "@/interfaces/targeting";
import { mappingTargetingKeys, resolveTargetingExpressions } from "@/utils/resolveObjectArray";
import { isEmpty, isUndefined } from "lodash";
import omitBy from "lodash/omitBy";
import { AxiosGet, AxiosPatch, AxiosPost, GetData } from "./axios-service";
import { catchServiceErrors } from "@/utils/services-global";
import ROUTES from "@/api/routes";
const { APP_SITE, CUSTOM_LIST, TARGETING, GEO, EXCHANGE, ENVIRONMENT, PLATFORM, CONTENT, SEGMENT, VIDEO, MODELS } =
  ROUTES;

class TargetingService {
  async all(filters?: TargetingFilters, options?: SortingOption, formatter?: Boolean) {
    try {
      let filter = "";
      let option = "";

      if (!isUndefined(filters)) {
        filter = getFilters(filters);
      }

      if (!isUndefined(options)) {
        option += getOptions(options, "all");
      }

      const url = getURL(filter, option);

      const response = await AxiosGet(TARGETING.TARGETING_ROUTE + url);

      return Promise.resolve(!!formatter ? resolveTargetingExpressions(GetData(response)) : GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async create(targeting: TargetingDataCreate) {
    try {
      if (isEmpty(targeting.targeting_terms))
        return Promise.reject({
          success: false,
          message: "Targeting empty",
          errors: [],
        });
      const response = await AxiosPost(TARGETING.TARGETING_ROUTE, targeting);
      return Promise.resolve(GetData(response) as Array<Targeting>);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async update(targeting: TargetingDataUpdate) {
    try {
      if (isEmpty(targeting.targeting_terms))
        return Promise.reject({
          success: false,
          message: "Targeting empty",
          errors: [],
        });
      const response = await AxiosPatch(`${TARGETING.TARGETING_ROUTE}/${targeting.id}`, targeting);
      return Promise.resolve(GetData(response) as Array<Targeting>);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }
  async getSummaryTargeting(id: number) {
    try {
      const response = await AxiosGet(`${TARGETING.SUMMARY_TARGETING_EXPRESSIONS_ROUTE}/${id}`);
      return Promise.resolve(GetData(response) as Array<any>);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  /**
   * getTargetingKeys
   * @returns
   */
  async getTargetingKeys() {
    try {
      const response = await AxiosGet(`${TARGETING.TARGETING_KEY_ROUTE}?mode=all`);
      const data = GetData(response);
      const targeting_keys = mappingTargetingKeys(data);
      return Promise.resolve(targeting_keys as Array<any>);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  /**
   * getTargetingKey
   * @param key
   * @returns
   */
  async getTargetingKey(key: string) {
    try {
      //console.debug(`[TargetingService::getTargetingKey] key: ${key} url: ${await this.getUrlByKey(key)}`);
      const response = await AxiosGet(await this.getUrlByKey(key));
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  /**
   * getAppNameByAtribute
   * @param term
   * @param by_attribute
   * @returns
   */
  async getAppNameByAtribute(term: String, by_attribute: String) {
    try {
      const url = APP_SITE.APP_NAME_ROUTE + "?term=" + term + "&by_attribute=" + by_attribute;
      const response = await AxiosGet(url);
      return Promise.resolve(GetData(response) as Array<AppName>);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  /**
   * Realiza una búsqueda de elementos basada en un término específico.
   * @param params - Parámetros de la búsqueda.
   * @returns - Lista de resultados obtenidos de la API.
   **/
  async getSearchByTerm(params: SearchByTermParams): Promise<any[]> {
    try {
      const { key, term, by_attribute } = params;

      // Construcción de los parámetros de la URL
      const queryParams = new URLSearchParams({ term: term ?? "" });

      if (by_attribute) {
        queryParams.append("by_attribute", by_attribute);
      }

      // Generación de la URL final con parámetros
      const baseUrl = await this.getUrlByKey(key);
      const finalUrl = `${baseUrl}?${queryParams.toString()}`;

      // Realiza la petición GET con filtros aplicados
      const response = await AxiosGet(finalUrl, {
        params: omitBy(params.filters, isEmpty),
      });

      // Extrae los datos de la respuesta y los retorna
      return Promise.resolve(GetData(response) as Array<any>);
    } catch (error) {
      // Manejo de errores centralizado
      return await catchServiceErrors(error);
    }
  }

  /**
   * getSitesByAtribute
   * @param term
   * @param by_attribute
   * @returns
   */
  async getSitesByAtribute(term: String, by_attribute: String) {
    try {
      const url = APP_SITE.SITE_ROUTE + "?term=" + term + "&by_attribute=" + by_attribute;
      const response = await AxiosGet(url);
      return Promise.resolve(GetData(response) as Array<Site>);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async getTargetingPredicates() {
    try {
      const response = await AxiosGet(TARGETING.TARGETING_PREDICATE_ROUTE);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async getUrlByKey(key: string): Promise<string> {
    const URL_MAP: Record<string, string> = {
      // app_site
      app_bundle_list: APP_SITE.APP_BUNDLE_LIST_ROUTE,
      app_name: APP_SITE.APP_NAME_ROUTE, // para busqueda por term
      app_id_list: APP_SITE.APP_ID_ROUTE,
      deal_id: CUSTOM_LIST.CUSTOM_LIST_EXCHANGE_ROUTE, // ROUTES.DEAL_ID_ROUTE ?
      deal_id_list: APP_SITE.DEAL_ID_LIST_ROUTE,
      domain_list: APP_SITE.DOMAIN_ROUTE,
      placement_id: APP_SITE.PLACEMENT_ROUTE,
      placement_list: APP_SITE.PLACEMENT_ROUTE,
      publisher_id: APP_SITE.PUBLISER_ID_ROUTE,
      publisher_id_list: APP_SITE.PUBLISER_ID_LIST_ROUTE,
      site_list: APP_SITE.SITE_LIST_ROUTE,
      site: APP_SITE.SITE_ROUTE, // para busqueda por term

      // content
      content_category: CONTENT.CONTENT_CATEGORY_ROUTE,
      content_rating: CONTENT.CONTENT_RATINGS_ROUTE,
      language: CONTENT.CONTENT_LENGUAGE_ROUTE,

      // environment
      interstitial: ENVIRONMENT.INTERSTITIAL_FLAGS,
      interstitial_type: ENVIRONMENT.INTESTITIAL_TYPES,
      video_api: ENVIRONMENT.ENVIRONMENT_VIDEO_ROUTE,
      topframe: ENVIRONMENT.ENVIRONMENT_TPFRAME_ROUTE,
      ad_size: ENVIRONMENT.CREATIVE_SIZE_ROUTE,
      ad_position: ENVIRONMENT.AD_POSITION_ROUTE,
      environment_type: ENVIRONMENT.ENVIRONMENT_TYPE_ROUTE,
      rewarded: ENVIRONMENT.ENVIRONMENT_REWARDED_ROUTE,
      native_layout: ENVIRONMENT.NATIVE_NATIVE_LAYOUT_ROUTE,

      // exchange
      inventory_source: EXCHANGE.INVENTORY_SOURCE_ROUTE,
      auction_type: EXCHANGE.AUCTION_TYPE_ROUTE,

      // geo
      city: GEO.CITY_ROUTE,
      country: GEO.COUNTRY_ROUTE,
      region: GEO.REGION_ROUTE,
      lat_long_list: GEO.LAT_LONG_ROUTE,

      // platform
      browser: PLATFORM.PLATFORM_BROWSER_ROUTE,
      browser_version: PLATFORM.PLATFORM_BROWSER_VERSION_ROUTE,
      device_make: PLATFORM.PLATFORM_DEVICE_MAKE_ROUTE,
      device_model: PLATFORM.PLATFORM_DEVICE_MODEL_ROUTE,
      device_screen_size: PLATFORM.PLATFORM_DEVICE_SCREEN_SIZE_ROUTE,
      device_type: PLATFORM.PLATFORM_DEVICE_TYPE_ROUTE,
      operating_system: PLATFORM.PLATFORM_OPERATING_SYSTEM_ROUTE,
      operating_system_version: PLATFORM.PLATFORM_OPERATING_SYSTEM_VERSION_ROUTE,
      bandwidth: PLATFORM.PLATFORM_BANDWITDH,
      carrier: PLATFORM.PLATFORM_CARRIER_ROUTE,

      // user
      segment: SEGMENT.SEGMENTS_ROUTE,
      user_id: SEGMENT.SEGMENTS_USERS_ROUTE,

      // video
      companion_required: VIDEO.VIDEO_COMPANION_REQUIRED,
      playback_method: VIDEO.VIDEO_PLAYBACK_METHOD,
      player_size: VIDEO.VIDEO_PLAYER_SIZE,
      start_delay: VIDEO.VIDEO_START_DELAY,
      video_placement_type: VIDEO.VIDEO_PLACEMENT_TYPE,

      // audio
      audio_start_delay: VIDEO.VIDEO_START_DELAY,

      // bid_model_id
      bid_model_id: MODELS.BIDMODELS_LIST_ROUTE,
    };

    return URL_MAP[key] ?? "";
  }
}

function getFilters(filters: TargetingFilters): string {
  let filter = "";

  const id = !!filters.id ? filters.id : "";
  const line_item_id = !!filters.line_item_id ? filters.line_item_id : "";
  const active = typeof filters.active === "undefined" ? "" : !!filters.active ? 1 : 0;

  filter +=
    "filters[advertisers.id]=" +
    id +
    "&filters[line_item_id]=" +
    line_item_id +
    "&filters[advertisers.active]=" +
    active;

  return filter;
}

function getOptions(options: SortingOption, mode: string, paginated?: Paginated): string {
  let option = "";

  const sort = isUndefined(options.sort) ? "" : options.sort;
  const order = isUndefined(options.order) ? "" : options.order;

  option += "sort=" + sort + "&order=" + order + "&mode=" + mode;

  if (mode == "paginated") {
    option += "&page=" + paginated?.page + "&limit=" + paginated?.limit;
  }

  return option;
}

function getURL(filters: string, options: string): string {
  let url = "";

  if (!isEmpty(filters) && !isEmpty(options)) {
    url = "?" + filters + "&" + options;
  } else if (!isEmpty(filters)) {
    url = "?" + filters;
  } else if (!isEmpty(options)) {
    url = "?" + options;
  }

  return url;
}

export default new TargetingService();
